/* Shlok css start */
/* Header */
.store-nav-tab3 {
  margin-top: -41px;
  margin-left: 77px;
  position: relative;
  z-index: 1;
}
.store-dashboardImg1 {
  width: 20px;
  height: 19px;
}
.store-myTicket {
  width: 18px;
  height: 17px;
  margin-right: 6px;
  margin-top: -2px;
}
.store-claim-logo {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-top: -2px;
}
.store-nav-tab1 {
  margin-top: -3%;
  margin-left: 27%;
  position: relative;
  z-index: 1;
}
.store-claim-space {
  margin-top: -2.9%;
  margin-left: 39%;
}
.storeHeader1 {
  width: 26px;
  height: 26px;
  margin-top: 13px;
  margin-left: 40px;
}
.store-chatImg {
  width: 25px;
  height: 25px;
  margin-top: -4px;
}
.store-setting {
  width: 24px;
  height: 24px;
}
.store-notifi {
  width: 24px;
  height: 24px;
}
.store-main-nav {
  margin-left: 18.5%;
}
.store-bitmap5 {
  width: 30px;
  height: 30px;
  margin-top: -25px;
  margin-left: 155px;
  background-color: #d8d8d8;
  border: 1px solid #979797;
  border-radius: 50%;
  font-size: 10px;
  line-height: 27px;
  text-align: center;
  cursor: pointer;
}
/* End header */
/* ***************************** */
/* Campaign tab css start */
.closebtn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 90px;
  height: 31px;
  min-width: 68px !important;
  cursor: pointer;
}
.hdrcloselabel {
  color: #ffffff;
  font-family: Lato;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  text-transform: initial;
  cursor: pointer;
}
.store button {
  padding: 0px;
}
.table-cntr-card .card-body {
  padding: 0;
}
.table-cntr-card {
  height: 0;
}
.table-cntr-card table thead tr th {
  color: #989898;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  width: 113px;
  text-align: left;
}
.contactBtnGreen {
  background-color: #9cc541;
  border-radius: 4px;
  box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.5);
  width: 105px;
  height: 31px;
}
.notConnectedBtnRed {
  background-color: #c0505f;
  border-radius: 4px;
  box-shadow: inset 0 0 7px 0 rgba(0, 0, 0, 0.5);
  width: 105px;
  height: 31px;
  text-transform: initial;
}
.followUpBtnYellow {
  background-color: #f5a623;
  border-radius: 4px;
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 7px 0 rgba(0, 0, 0, 0.5); */
  width: 105px;
  height: 31px;
  text-transform: initial;
}
.followUpBtnBlue {
  background-color: #5c53ee;
  border-radius: 4px;
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 7px 0 rgba(0, 0, 0, 0.5); */
  width: 105px;
  height: 31px;
  text-transform: initial;
}
.table-btnlabel {
  color: #ffffff;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  text-transform: initial;
  /* line-height: 16px;
  text-align: left; */
  line-height: 31px;
  text-align: center;
  margin-right: 2px;
  opacity: 0.65;
}
.campaign-status-btn:checked + label {
  opacity: 1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 7px 0 rgba(0, 0, 0, 0.5);
}
.saveBtn {
  background-color: #ecf2f4;
  border: 1px solid #2561a8;
  border-radius: 4px;
  width: 59px;
  height: 31px;
}
.saveLabel {
  color: #2561a8;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  text-transform: initial;
}
.raisedticket-Btn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 101px;
  height: 31px;
  text-transform: initial;
}
.addcommentCmp-Btn {
  background-color: #bed883;
  border-radius: 4px;
  width: 101px;
  height: 31px;
  text-transform: initial;
}
.raise-ticketLbl {
  color: #ffffff;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
  line-height: 16px;
  text-align: left;
}
.txtStore {
  padding-top: 0px !important;
  background: #e5e9ed url(/static/media/calendar-black.d0d589e5.svg) no-repeat
    right 15px center;
}
.dateTimeStore {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 183px;
  height: 31px;
  border: none;
  padding-left: 10px;
}
.dateTimeStore-highlight {
  box-shadow: 0 0 0 1.5px rgba(247, 0, 0, 0.6);
}
.responceDrop-down {
  /* background-color: #ecf2f4; */
  background: #ecf2f4 url("./../Images//dropdown3.png") no-repeat center right
    10px !important;
  background-size: 12px !important;
  padding-right: 32px !important;
  border-radius: 4px;
  width: 190px;
  height: 31px;
  padding-left: 12px;
}
.raise-ticket-popup {
  padding: 10px 20px;
}
.not-contacted-count {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  border: 1px solid #ff7c21;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: #ff7c21;
  bottom: calc(100% + 4px);
  right: 5px;
}
.not-contacted-count:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 18px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-top: 4px solid #ff7c21;
  border-right: 3px solid transparent;
}
.cncl-icn {
  margin-left: auto;
  display: block;
  margin-right: 5px;
  cursor: pointer;
}
.raise-ticket-popup label {
  display: block;
  color: #9b9b9b;
}
.raise-ticket-popup input,
.raise-ticket-popup textarea,
.raise-ticket-popup select {
  background: #f0f0f0;
  border: 1px solid #d8d0d0;
  border-radius: 3px;
  padding: 3px 10px;
  margin-top: 5px;
  width: 100%;
}
.raise-ticket-popup select {
  background: #f0f0f0 url("../Images/dropDown.png") no-repeat right 12px center;
}
/* Compaign css End */
/* Shlok css end */
/* --------------------------------------------- */

/* chetan css starts */

/* store task starts */
.store-task-tabs ul {
  border-bottom: 0;
}
.store-task-tabs {
  background-color: #ecf2f4;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.store-task-tabs .nav-tabs .nav-link {
  font-weight: 400;
  padding: 17px 15px;
  font-size: 16px;
  font-weight: 600;
}
.store-task-tabs .nav-tabs .nav-link.active {
  font-weight: 700;
  border-bottom: 0;
  background-color: transparent;
}
.store-task-tabs button {
  font-size: 14px;
  padding: 10px 40px;
  margin-right: 15px;
}
.store-task-tab-cont {
  padding-right: 15px;
  padding-left: 15px;
}

.store-task-tab-cont table th {
  font-size: 12px;
  padding: 15px 25px;
}
/* store task ends */

/* store task view starts */
.store-task-view-top {
  padding: 10px 20px;
  background-color: #ecf2f4;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.str-img {
  width: 30px;
  height: 30px;
  background-color: #f8e71c;
  border-radius: 50%;
  overflow: hidden;
  line-height: 25px;
  text-align: center;
}
.str-img img {
  width: 18px;
}
.str-owner p {
  font-size: 14px;
  color: #000;
  margin-left: 10px;
  font-weight: 700;
}
.str-owner {
  display: flex;
  align-items: center;
}
.str-dtls select {
  background: #2561a8 url("./../Images/down-white.png") no-repeat right 20px
    center;
  border-radius: 4px;
  color: #fff;
  border: 0;
  font-size: 14px;
  padding: 11px 45px 11px 20px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-left: 25px;
  min-width: 205px;
}
.str-dtls select option {
  color: #2561a8;
  background-color: #fff;
  text-transform: initial;
  font-weight: 700;
}
.task-view-cntr {
  background-color: #fff;
  border-radius: 4px;
  padding: 25px 40px;
  height: 100%;
}
.task-view-cntr label {
  color: #9b9b9b;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
}
.task-view-cntr input,
.task-view-cntr select,
.task-view-cntr textarea {
  font-size: 14px;
  font-weight: 700;
  width: 100%;
  border: 0;
  padding: 12px 15px;
  background-color: #ecf2f4;
  border-radius: 4px;
}
.task-view-cntr select {
  background: #ecf2f4 url("./../Images/down-1.png") no-repeat right 20px center;
  background-size: 18px;
  padding-right: 50px;
}
.task-view-cntr textarea {
  height: 150px;
  resize: none;
}
.task-view-cntr .row > div {
  padding-right: 10px;
  padding-left: 10px;
}
.task-view-cntr .row {
  margin-right: -10px;
  margin-left: -10px;
}
.store-cmnts {
  font-size: 16px !important;
  font-weight: 400 !important;
  padding: 15px 30px !important;
}
.store-cmnts::placeholder {
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  color: #9b9b9b;
}
.add-cmnts p {
  color: #9b9b9b;
  font-weight: 700;
}
.add-cmnts a {
  color: #fff;
  background-color: #2561a8;
  border-radius: 4px;
  font-size: 14px;
  text-transform: uppercase;
  padding: 12px 25px;
  display: inline-block;
}
.add-cmnts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.cmnts span {
  color: #9b9b9b;
  font-size: 12px;
  font-weight: 700;
}
.cmnts p {
  color: #505050;
  font-size: 13px;
  font-weight: 700;
}
.cmnts-sctn {
  padding: 20px 0;
  border-bottom: 1px solid #e4e4e4;
  margin-top: 10px;
}
.outer-cmnts .cmnts-sctn:last-child {
  border-bottom: 0;
}
.task-view-right p {
  color: #4a4a4a;
  font-weight: 700;
  font-size: 16px;
}
.task-closure-progress {
  height: 10px;
  background-color: #ecf2f4;
  border-radius: 5px;
  margin-left: 12px;
}
.task-closure-progress .progress-bar {
  background: linear-gradient(to right, #f9c957 0%, #f66c1c 100%);
  box-shadow: none;
}
.task-closure .dte {
  white-space: nowrap;
  font-size: 15px;
  line-height: 1;
  margin-top: -2px;
}
.nos-days {
  font-size: 14px !important;
  text-align: right;
  width: 65%;
  position: relative;
  left: 17px;
  margin-top: 2px;
}
/* store task view ends */

/* chetan css ends */

/* ------------Shlok css---------------------------- */
/* ----------------Store Manager css--------------------- */
.table-expandable-sctn1 {
  background: #ecf2f4;
}
.table-expandable-sctn1 button {
  cursor: pointer;
}
.table-expandable-sctn1 .nav-tabs .nav-link {
  padding-top: 20px;
  padding-bottom: 20px;
  background: transparent;
}
.table-expandable-sctn1 .nav-tabs .nav-link.active {
  color: #2561a8;
  border-bottom: 2px solid #2561a8;
  box-shadow: none;
  /*background: #e3e9eb;*/
}
.table-expandable-sctn1 .nav-tabs {
  border-color: #dae2e5;
  position: relative;
}
.table-expandable-sctn1 input[type="text"] {
  width: 100%;
  border-radius: 4px;
  padding: 10px 50px 10px 20px;
  border: 0;
  color: #4a4a4a;
}
.table-expandable-sctn1 input[type="text"].no-bg {
  background: #fff none;
  padding: 10px 20px;
}
.table-expandable-sctn1 input::placeholder {
  font-size: 16px;
  padding: 0;
  color: #a7a7a7;
  font-weight: 400;
}
.table-expandable-sctn1 select {
  width: 100%;
  border-radius: 4px;
  padding: 10px 45px 10px 20px;
  border: 0;
  background: #fff url("./../Images//table-arr.png") no-repeat right 20px center;
  background-size: 13px;
  color: #a7a7a7;
}
.table-expandable-sctn1 .tab-content .row {
  padding: 20px 0;
  border-bottom: 1px solid #dae2e5;
}
.table-expandable-sctn1 .tab-content .row:last-child {
  border-bottom: 0;
}
.table-expandable-sctn1 button {
  background-color: #fff;
  margin-right: 15px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.table-expandable-sctn1 button.btn-inv {
  color: #fff;
  background-color: #2561a8;
}
.table-expandable-sctn1 button.btn-dis {
  /* background-color: rgba(37, 97, 168, 0.4);
  border-color: rgba(37, 97, 168, 0.4);
  color: rgba(255, 255, 255, 0.4); */
  opacity: 0.4;
}
.table-expandable-sctn1 .tab-content button img {
  margin-right: 12px;
  top: -3px;
}
.table-expandable-sctn1 .tab-content button:last-child {
  margin-right: 0;
}
.table-expandable-sctn1 .tab-content .row.all-row {
  padding: 0;
  padding-top: 25px;
}
.table-expandable-sctn1 .tab-content .row.all-row > div {
  margin-bottom: 25px;
}
.table-expandable-sctn1 .react-datepicker-wrapper input[type="text"] {
  background: #fff url(/static/media/calendar-black.d0d589e5.svg) no-repeat
    right 15px center;
}
.table-expandable-sctn1 .react-datepicker-popper {
  z-index: 2;
}
.table-expandable-sctn1 .react-datepicker button {
  min-width: auto;
}
.table-expandable-sctn1 .react-datepicker-wrapper {
  width: 100%;
}
.bill-graph-list {
  font-weight: 300;
  text-align: left;
  margin-left: 25px;
  margin-top: 30px;
}
.bill-graph-list li {
  padding: 4px 0;
  white-space: nowrap;
  display: block;
  font-size: 15px;
}
.bill-graph-list li b {
  font-weight: 700;
}
.tic-bill-graph .nv-x {
  font-size: 12px;
  font-weight: 700;
}
.tic-bill-graph .nv-y {
  font-size: 8px;
  color: #4e4e4e;
}
.tic-bill-graph .nv-chart,
.tic-bill-graph .nvd3-svg {
  width: 100%;
}
.apexcharts-toolbar {
  display: none !important;
}
.opendonutChart .apexcharts-canvas .position-right {
  right: 40px !important;
}
/* Task by ticket css start */
.taskByTable table th,
.taskByTable table td {
  font-size: 12px;
  padding: 20px 20px;
}
/* css end */
/* Edit Store Task page css start by Shlok */
.edit-storeTask-header {
  background-color: #ecf2f4;
  width: 100%;
  height: 57px;
  padding: 15px 20px 0px;
}
.store-header-lbl {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
}
.btnstore-last {
  display: block;
  float: right;
  margin-top: -8px;
}
.submitAs-reopen {
  background-color: #2561a8;
  border-radius: 4px;
  width: 210px;
  height: 43px;
  margin-left: 20px;
  cursor: pointer;
}
.store-Edit-lbl {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  margin-bottom: 7px;
}
.store-card-padding {
  padding: 20px 40px;
  margin-left: -15px;
  margin-top: 12px;
  margin-bottom: 12px;
  /* width: 780px; */
}
.store-edit-txt {
  background-color: #ecf2f4;
  border-radius: 4px;
  height: 43px;
  border: none;
  padding: 10px;
}
.store-mrg {
  margin-top: 20px;
}
.store-crmrole-Btn {
  background-color: #2561a8;
  color: #fff;
  font-size: 14px;
  padding: 3px 20px;
  border-radius: 5px;
  cursor: pointer;
  min-width: 10px;
}
.textarea-store {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 148px;
  resize: none;
  border: none;
  padding: 10px;
}
.textarea-store-comments {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 100%;
  height: 129px;
  resize: none;
  border: none;
  padding: 10px;
}
.butn-store {
  background-color: #2561a8;
  color: #fff;
  font-size: 14px;
  padding: 8px 25px;
  border-radius: 5px;
  cursor: pointer;
  /* height: 40px; */
  /* width: 155px; */
  float: right;
  text-transform: uppercase;
}
.store-mrg-1 {
  margin-top: 25px;
}
.store-hrLbl {
  color: #000000;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  float: right;
}
.naman-r-store {
  color: #000000;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  padding-left: 6px;
  cursor: pointer;
}
.store-comment {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}
.store-cmt-comment {
  color: #505050;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  width: 100%;
}
.store-card-2 {
  padding: 20px 30px;
  /* margin-left: -60px; */
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
  height: 69vw;
}
.store-card-3 {
  padding: 20px 30px;
  /* margin-left: -60px; */
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
  height: auto;
}
.naman-r-store-2 {
  color: #000000;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
}
.store-edit-data {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  width: 100%;
  text-align: left;
}
.store-edit-data-1 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding-left: 7px;
}
.store-mrg-3 {
  margin-top: 25px;
}
.task-clouserDate {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  margin-top: 10px;
}
.store-date {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
}
.progressbar-2 {
  width: 70%;
  margin-left: 6px;
  margin-bottom: 3px;
}
.dis-btns {
  opacity: 0.5;
  pointer-events: none;
}
#dis-sms,
#dis-msngr,
#dis-bot,
#dis-share {
  pointer-events: none;
}
.progressbar-lbl {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  /* float: right; */
  /* margin-right: 90px; */
}
.store-hdrtMdal {
  padding: 7px 113px 10px 20px;
}
.modal-lbl {
  color: #2561a8;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}
.modal-lbl-1 {
  color: #16cba3;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}
.modal-lbl-2 {
  color: #696969;
  font-size: 15px;
  font-weight: 600;
}
#SubmitReopn-popup {
  border-radius: 8px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  padding: 10;
  margin-right: 25px;
  margin-top: 45px;
  /* margin-bottom: 4px; */
  display: inline-block;
}
.storeImg-11 {
  height: 18px;
  width: 19px;
  margin: 7px;
}
.oval-5-1-new-store {
  background-color: #f8e71c;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  display: inline-block;
}
/*  css End edit Store Task*/
/* Store Task by tikcet css start by Shlok */
.store-header-task {
  margin-top: -20px;
  margin-left: -288px;
}
.headerBtn-store {
  margin-top: -41px;
  float: right;
  margin-right: 20px;
}
.raisedClaim-storeBtn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 141px;
  height: 43px;
  margin-left: 20px;
  cursor: pointer;
}
.raisedClaim-lbl {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
}
.btn-store-resolved {
  background-color: #2561a8;
  border-radius: 4px;
  height: 43px;
  margin-left: 15px;
  cursor: pointer;
}
.ImgBlue-lbl {
  width: 20px;
  height: 14px;
  padding: 3px;
  margin-left: 7px;
}
.noEditImg {
  height: 18px;
  width: 19px;
  margin-left: 660px;
  margin-top: -30px;
}
.noEditImg-1 {
  height: 18px;
  width: 19px;
  margin-left: 660px;
  margin-top: -252px;
}
.store-card-padding-ticket {
  padding: 20px 40px;
  margin-left: -15px;
  margin-top: 12px;
  margin-bottom: 12px;
  width: 780px;
  height: 100vh;
}
.hrMargin {
  margin-top: 20px;
  margin-bottom: 15px;
}
.newtab {
  width: 100%;
  background: none;
  /* padding-top: 45px;
    padding-bottom: 15px; */
  padding-left: 0;
  padding-right: 0;
}
/*  Store Task by tikcet css end*/
/* Claim page by shlok */
.main-Claimdiv {
  padding: 5px 15px 15px;
}
.claimTbl table tr td:last-child,
table tr th:last-child {
  text-align: right;
  float: left;
}
.claim-headPhoneImg {
  width: 18px;
  height: 19px;
  margin-right: 7px;
}
.claimTbl .claim-span {
  font-size: 10px !important;
}
.claim-comment {
  color: #505050;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}
.claim-bataShoes {
  width: 350px;
  height: 197px;
  margin-left: 0px;
}
.closure-date {
  font-size: 12px;
  font-weight: 700;
  color: #777;
  margin: 0;
  text-align: center;
}
.col-md-10-card {
  margin-left: 5px;
  flex: 0 0 75%;
  max-width: 70%;
}
.claim-customer-padding {
  padding: 60px;
  padding-left: 6%;
  padding-top: 5px;
}
/* Claim page end */
/* StoreAddTask css start by kalpesh */
.card1 {
  width: 100%;
  padding: 42px 20px;
  margin-left: 0;
  margin-right: 0;
}
.store-Edit-lblcre {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 25px;
}
.store-Edit-lbl1 {
  color: #9b9b9b;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  margin-bottom: 7px;
}
.cardmargin {
  margin-left: -30px;
  height: 100%;
  /* height: 49.6vw; */
}
/* StoreAddTask css end */
/* ClaimApproveReject css start for header button and bottom page by Kalpesh */
.claim-title1 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 550;
  line-height: 22px;
  text-align: left;
  padding: 15px 0 0 0;
}
.btn-approrej {
  float: right;
  margin-top: 4px;
}
.btn-approrej1 {
  background-color: #2561a8;
  border-radius: 4px;
  border-color: #2561a8;
  width: 155px;
  height: 40px;
  font-size: 14px;
  cursor: pointer;
  color: white;
  margin: 3px 0px 8px 12px;
}
.oval-approve {
  background-color: #f8e71c;
  border-radius: 100%;
  width: 31px;
  height: 31px;
  float: left;
  padding: 4px;
  margin: 9px 15px 9px 35px;
}
.card-radius2 b {
  font-size: 16px;
  font-weight: 700;
  line-height: 2;
}
.card-radius2 label {
  font-size: 16px;
  font-weight: 400;
}
.a {
  border-radius: 50%;
  background-color: #2561a8;
  color: white;
  padding: 3px 10px 5px 11px;
  font-weight: 500;
  margin-right: 10px;
}
.cardbor {
  border: 1px solid #e0e0e0 !important;
}
.alankrit {
  margin-bottom: 20px;
  display: grid;
}
/* ClaimApproveReject css end */
/* Raiseclaim search collapse css start kalpesh */
.Searchline {
  border-bottom: 1px solid #e3e3e3;
  width: 100%;
  margin-top: 10px;
  margin-left: 0px;
  margin-right: 0px;
}
.uploadsearch-text {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
}
.uploadsearchbtn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 154px;
  height: 43px;
  cursor: pointer;
}
.uploadsearchbtn-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
}
.searchdownload {
  color: #2561a8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
}
.uploadsechmargin {
  margin-bottom: 15px;
}
.uploadsearch {
  text-align: center;
  margin-top: 70px;
}
.uploadsearch-space {
  margin-bottom: 60px;
}
.allign-reassign {
  margin-left: 35px !important;
  margin-top: -10px;
}
.searchtext {
  background-color: #ffffff;
  border: 1px solid #979797;
  border-radius: 4px;
  width: 299px;
  height: 43px;
  float: right;
  padding: 10px;
}
.searchImg-raise {
  width: 20px;
  height: 20px;
  float: right;
  margin-right: -280px;
  margin-top: 12px;
}
.oderDetails-table .ReactTable .rt-thead.-header {
  background-color: #fff;
}
.orderdetailtext {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  margin-top: 10px;
}
/* Raiseclaim search collapse css start kalpesh */

/* Department-Master Css Start Kalpesh */
.deptMaster .ReactTable .rt-thead .rt-tr {
  font-size: 12px;
}
.deptMaster .ReactTable {
  border: none;
}
.right-sect-collapse .div-cntrdept {
  padding: 0 10px;
}
.editdeptpopover {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0);
  padding: 25px 10px;
  /* margin-bottom: 25px; */
}
.editdeptpopover input,
.editdeptpopover select {
  background-color: #ecf2f4;
  border-radius: 4px;
  width: 250px;
  color: #000;
  display: block;
  border: 0;
  padding: 11px 15px;
  margin-top: 5px;
}
.delcan-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -25px;
}
/* Department-Master Css end */
/* SLA Template Department Start by Kalpesh */
.slatemp-text-1 {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  display: inline-block;
  width: 33%;
}
.slatempreact .ReactTable {
  border-left: none;
  border-right: none;
}
.slatempreact {
  border-left: 1px solid rgb(0, 0, 0, 0.1);
  border-right: 1px solid rgb(0, 0, 0, 0.1);
}
/* SLA Template Department End */
/* Config add pop-up css start by Kalpesh */
.config-button {
  float: right;
  margin: 10px 38px 10px 0;
}
.config-buttontext {
  width: 140px;
  height: 40px;
  cursor: pointer;
  background-color: #2561a8;
  color: #fff;
}
.config-tab {
  width: 100%;
}
.cancelImg-config {
  float: right;
  width: 25px;
  height: 25px;
  margin-top: 15px;
  cursor: pointer;
}
.templateName {
  text-align: left;
  padding: 25px 0px 20px 55px;
}
.template-text {
  font-size: 20px;
  font-weight: 600;
  color: #2561a8;
  font-family: lato;
}
.template-editor {
  width: 92% !important;
  margin-left: 40px !important;
}
.template-editor .cke_bottom {
  display: none;
}
/* Config add pop-up css End by Kalpesh */
.stinlitab {
  display: inline-table;
}
.StoreRaiseReact .hope {
  display: block;
  font-size: 10px;
}
.camp-datepick .react-datepicker__navigation--previous {
  min-width: auto;
}
.camp-datepick .react-datepicker__navigation--next {
  min-width: auto;
}
.camp-datepick span {
  display: grid;
}
.camp-datepick td {
  padding-top: 7px;
  padding-bottom: 7px;
}
.sml-fnt {
  font-size: 11px !important;
  user-select: none;
}
.reactstoreclaim .ReactTable {
  background-color: #f8f8f8;
}
.reacttableordermodal .ReactTable .rt-thead.-header {
  background-color: #f8f8f8 !important;
}
.raisereactTable svg:not(:root).svg-inline--fa {
  overflow: visible;
  color: #a7aaad;
  width: 20px;
  height: 18px;
  margin: -3px 0;
}
.storeplusline-12 {
  cursor: pointer;
  float: right;
  padding: 15px 0 0 0;
  height: 54px;
  pointer-events: none;
}
/* ----------------------------------------------- */
/* ------Change password btn css start-------- */
.userChangePW {
  text-align: center;
  font-size: 18px;
  color: #1555a2;
  cursor: pointer;
}
/* -------Change password btn css end------- */
.creation-date-range .ant-calendar-picker-input {
  height: 41px !important;
  background-color: #fff !important;
}
.creation-date-range .ant-calendar-range-picker-input,
.creation-date-range .ant-calendar-range-picker-separator {
  color: #a7a7a7 !important;
}
.creation-date-range div {
  margin-bottom: 0 !important;
}
/* ------------------Campain Css Start 29.04.2020------------------------- */
.custom-tableak {
  padding: 20px;
}
.custom-tableak
  .custom-antd-table
  .ant-table-thead
  > tr
  > th
  .ant-table-column-title {
  font-size: 13px !important;
}
.button-red {
  color: #ee6261 !important;
  font-weight: 600;
  border-radius: 50px;
  border: 1px solid #ee6261;
  padding: 6px 20px 9px;
  cursor: pointer;
  font-size: 12px;
  margin-right: 10px;
}
.button-blue {
  color: #2196f3 !important;
  font-weight: 600;
  border-radius: 50px;
  font-size: 12px;
  cursor: pointer;
  border: 1px solid #2196f3;
  padding: 6px 20px 9px;
  margin-right: 10px;
}
.button-red .ico {
  margin-right: 5px;
  vertical-align: bottom;
}
.button-blue .ico {
  margin-right: 5px;
}
.custom-tableak .sml-fnt {
  font-size: 12px !important;
}
.custom-tableak .cust-name {
  color: #2561a8;
  white-space: nowrap;
  font-weight: bolder;
  font-size: 14px;
  text-transform: capitalize;
  cursor: pointer;
}
.custom-tableak .table-btnlabel {
  box-shadow: none;
}
.custom-tableak
  .custom-antd-table.antd-table-campaign
  .ant-table-expanded-row
  .ant-table-tbody
  > tr:first-child
  > td {
  padding-top: 10px !important;
}
.custom-tableak
  .custom-antd-table.antd-table-campaign
  .ant-table-expanded-row
  .ant-table-tbody
  > tr
  > td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  background: #fcfcfc !important;
}
.custom-tableak .saveBtn {
  background-color: #fff;
  border: 1px solid #2561a8;
  border-radius: 4px;
  width: 75px;
  height: 30px;
  min-width: 5px;
  margin-right: 10px;
  cursor: pointer;
}
.custom-tableak .saveLabel {
  text-align: center;
}
.custom-tableak .raisedticket-Btn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 100px;
  height: 30px;
  color: #fff;
  text-transform: initial;
}
.custom-tableak .txtStore {
  /* padding-top: 0px !important;
  background: #e5e9ed url(/static/media/calendar-black.d0d589e5.svg) no-repeat right 15px center */
  background-size: 14px;
}
.antcustom
  .ant-popover-placement-bottom
  .ant-popover-content
  .ant-popover-arrow {
  display: none;
}
.antcustom .ant-popover-inner {
  border-radius: 20px !important;
}
.antcustom .ant-popover-placement-bottom,
.antcustom .ant-popover-placement-bottomLeft,
.antcustom .ant-popover-placement-bottomRight {
  padding-top: 1px !important;
}
.antcustom .ant-popover-arrow {
  display: none !important;
}
.ant-prodesc .ant-popover-arrow {
  display: inherit !important;
}
.custompop .poptitle {
  font-size: 18px;
  font-weight: bolder;
  margin-bottom: 15px;
}
.broadcast-icon {
  display: inline;
  float: left;
}
.broadcast-icon img {
  height: 18px;
  cursor: pointer;
}
.antbroadcast {
  left: 890px !important;
  /* width: 330px; */
  width: 320px;
}
#customername-popup {
  border-radius: 25px;
  position: relative;
  padding: 30px;
  width: 800px;
}
#CampAddCmt-popup {
  border-radius: 25px;
  position: relative;
  padding: 20px;
  width: 450px;
  top: -35px;
}
.cust-icon {
  /* position: absolute; */
  top: 10px;
  right: 10px;
  height: 20px;
  cursor: pointer;
  float: right;
}
.nr-initials {
  float: left;
  width: 7%;
  background: #0080ff;
  border-radius: 8px;
  padding: 12px 8px;
  text-align: center;
  color: #fff;
  font-size: 17px;
}
.nr-name {
  float: right;
  padding-left: 5px;
  width: 92%;
}
.nr-name h3 {
  font-size: 24px;
  letter-spacing: 1px;
  font-weight: 600;
}
.nr-name span {
  font-size: 18px;
  color: #0080ff;
}
.nr-name p {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  color: #cf6d0f;
}
.lifetimevalue {
  background-color: #f2f5f8;
  border-radius: 10px;
  /* margin: 20px 0 15px 0; */
  margin: 20px 0 0px 0;
}
.lifetimevalue h4 {
  color: #8694a0;
  font-size: 16px;
  font-weight: bold;
}
.lifetimevalue label {
  color: #000;
  font-size: 26px;
  font-weight: normal;
  margin-top: 1px;
}
.keyinsights {
  background-color: #f2f5f8;
  border-radius: 10px;
  margin: 0;
  padding: 18px 23px;
  height: calc(100% - 128px);
  margin-top: 15px;
}
.keyinsights h4 {
  color: #8694a0;
  font-size: 16px;
  margin-bottom: 12px;
  font-weight: normal;
}
.keyinsights p {
  color: #828282;
  font-size: 13px;
  font-weight: 600;
  /* margin-bottom: 10px; */
  margin-bottom: 0px;
  max-height: 120px;
  overflow: auto;
  padding-right: 10px;
}
.keyinsights-big p {
  max-height: 150px;
}
.keyinsights p::-webkit-scrollbar {
  width: 4px;
}
.keyinsights p::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 10px;
}
.keyinsights p::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.keyinsights p::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.ki-al {
  margin: 20px 0 15px 0;
  height: calc(100% - 20px);
}
.productbox {
  background-color: #f2f5f8;
  border-radius: 10px;
  margin: 20px 0 0 0;
  padding: 18px 15px;
  /* height: calc(100% - 33px); */
  height: calc(100% - 18px);
}
.productbox .tab-content {
  padding: 24px 0px 0;
}
.productbox .prodscro {
  /* max-height: 210px; */
  max-height: 200px;
  overflow: auto;
  padding: 0 15px;
}
.productbox .nav-tabs .nav-link.active {
  color: #8694a0;
  border-bottom: 2px solid #8694a0;
  box-shadow: none;
  background-color: transparent;
  font-size: 14px;
  padding: 0;
  padding-bottom: 3px;
  display: inline;
}
.productbox .nav-tabs .nav-link {
  color: #8694a0;
  box-shadow: none;
  background-color: transparent;
  font-size: 14px;
  padding: 0;
}
.productbox .nav-item {
  display: block;
  width: 50%;
}
.broadChannel {
  display: "block";
  height: "30px";
  line-height: 30px;
}
/* .pro-slidercam td:first-child {
  padding-right: 10px;
}
.pro-slidercam td:last-child {
  padding-left: 10px;
} */
.pro-slidercam .shoeimg {
  height: 100%;
  width: auto;
  display: block;
  margin: auto;
}
.pro-slidercam .imgbox {
  height: 75px;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  background-color: #fff;
}
.pro-slidercam h4 {
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  margin-top: 5px;
  color: #333;
}
.pro-slidercam td {
  padding: 0 5px 10px 0;
  width: 50%;
  display: inline-block;
}
.pro-slidercam table td:nth-child(2) {
  padding: 0 0 10px 5px;
}
.pro-slidercam table td:nth-child(3) {
  padding: 0 5px 10px 0;
}
.pro-slidercam table td:nth-child(4) {
  padding: 0 0px 10px 5px;
}
.whatsappico {
  height: 18px;
  position: absolute;
  right: 0;
  top: 0;
}
.transactionbox {
  padding: 0 15px;
}
.transactionbox td {
  padding: 0 0 10px 0;
}
.transactionbox h5 {
  color: #8694a0;
  font-size: 13px;
  padding: 0 0 1px 0;
}
.transactionbox label {
  color: #666;
  font-size: 14px;
}
.trasactablist {
  background-color: #f9f9f9;
  border-radius: 10px;
  margin-top: 10px;
  /* padding: 15px; */
}
.trasactablist tr:last-child {
  margin-bottom: 15px;
}
.trasactablist th {
  padding: 0 0 5px;
  color: #999;
  font-size: 12.5px;
}
.transactionbox table thead tr,
.transactionbox table tbody tr {
  background-color: transparent !important;
  border: none;
}
.lifetimevalue table tbody tr,
.pro-slidercam table tbody tr {
  border: none;
}
.trasactablist td {
  padding: 5px 0 3px;
  font-size: 12.5px;
  color: #777;
  font-weight: 600;
}
.trasactablist .tabscrol {
  max-height: 90px;
  overflow: auto;
}
.trasactablist .tabscrol::-webkit-scrollbar {
  width: 4px;
}
.trasactablist .tabscrol::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 10px;
}
.trasactablist .tabscrol::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.trasactablist .tabscrol::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.productbox .prodscro::-webkit-scrollbar {
  width: 4px;
}
.productbox .prodscro::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 10px;
}
.productbox .prodscro::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.productbox .prodscro::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sharecamp {
  margin-top: 25px;
}
.sharecamp h4 {
  font-size: 17px;
  font-weight: bolder;
  color: #666;
  margin-bottom: 8px;
}
.sharecamp ul {
  margin-bottom: 0px;
  float: right;
}
.sharecamp li {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 7px;
  margin-right: 20px;
  min-width: 160px;
  text-align: center;
  color: #888;
  font-size: 15px;
  cursor: pointer;
}
.sharecamp li:last-child {
  margin-right: 0px;
}
.sharecamp .ico {
  margin-right: 7px;
  height: 24px;
}
.sharecamp .emailico {
  margin-right: 7px;
  height: 18px;
}
.cust-name .ico {
  margin-left: 10px;
}
.sharecampmob {
  display: none;
}
.keyingsightdrp {
  display: none;
}
.productbox .nav-tabs {
  overflow: inherit;
  border: none;
  padding: 0 15px;
}
.productdesc {
  padding: 15px;
  width: 250px;
}
.productdesc h4 {
  color: #333;
  font-weight: 600;
  margin-bottom: 4px;
  font-size: 16px;
}
.productdesc p {
  color: #999;
  font-size: 13px;
}
.productdesc table {
  margin: 10px 0;
}
.productdesc table tbody tr {
  border-bottom: none;
}
.productdesc td {
  padding: 7px 0;
}
.productdesc ul {
  margin: 0;
}
.productdesc li {
  line-height: 0;
  display: inline;
}
.productdesc label {
  color: #666;
  font-size: 13px;
}
.productdesc span {
  display: inherit;
  visibility: hidden;
}
.productdesc .colorblue {
  background-color: #2a72c7;
  padding: 2px 6px;
  border-radius: 50px;
  display: inline;
  margin-right: 5px;
}
.productdesc .colorblack {
  background-color: #3e3e3e;
  padding: 2px 6px;
  border-radius: 50px;
  margin-right: 5px;
  display: inline;
}
.productdesc .colorgrey {
  background-color: #bbbaba;
  padding: 2px 6px;
  border-radius: 50px;
  margin-right: 5px;
  display: inline;
}
.productdesc .colorRed {
  background-color: #ef1634;
  padding: 2px 6px;
  border-radius: 50px;
  margin-right: 5px;
  display: inline;
}
.productdesc .colorYellow {
  background-color: #ffff00;
  padding: 2px 6px;
  border-radius: 50px;
  margin-right: 5px;
  display: inline;
}

.productdesc .colorGreen {
  background-color: #008000;
  padding: 2px 6px;
  border-radius: 50px;
  margin-right: 5px;
  display: inline;
}
.productdesc .sizes a {
  color: #333;
  font-size: 13px;
  margin-right: 12px;
}
.productdesc .sizes a:hover,
.productdesc .sizes a:focus,
.productdesc .sizes a:active {
  color: #bbbaba;
}
.productdesc .sizes .active {
  color: #bbbaba;
}
.productdesc h3 {
  color: #333;
  font-size: 18px;
  font-weight: bold;
  padding-top: 5px;
}
.custom-tableak .dropdown-label {
  font-size: 12px;
}
.custom-tableak .table-btnlabel {
  font-size: 11px;
}

.custom-tableak .txtStore,
.custom-tableak .txtStore::placeholder {
  font-size: 13px;
}
.chatbotwid {
  min-width: 300px;
}
/* .ant-table-pagination {
  padding: 0 20px;
}
.ant-table-pagination .anticon {
  vertical-align: text-bottom;
}
.ant-table-pagination.ant-pagination {
  float: right !important;
  margin: 5px 16px !important;
}
.ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next {
  min-width: 20px !important;
  height: 20px !important;
  line-height: 18px !important;
}
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  font-size: 10px !important;
}
.ant-pagination-item {
  min-width: 20px !important;
  height: 20px !important;
  line-height: 18px !important;
}
.ant-pagination-item a {
  font-size: 11px !important;
} */
/* .broadcastimg {
  display: block;
} */
.broadcastpop .broadcasttitle {
  font-size: 15px;
  font-weight: bolder;
  margin-bottom: 10px;
}
.broadcastpop .broadcastsubtitle {
  color: #999;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
}
.broadcastpop .ant-radio-checked .ant-radio-inner {
  border-color: #2561a8;
}
.broadcastpop .ant-radio-inner::after {
  background-color: #2561a8;
}
.broadcastpop .ant-radio-wrapper {
  display: block;
  margin-bottom: 10px;
}
.executebtn {
  background-color: #2561a8;
  border-radius: 4px;
  width: 100%;
  height: 35px;
  min-width: 68px !important;
  cursor: pointer;
  margin-top: 15px;
  color: #fff;
  text-align: center;
  line-height: 0;
}
.innertabcollapse td {
  padding: 7px !important;
  font-size: 11px;
}
.lt-single td {
  display: block;
  padding: 43px 20px;
  text-align: center;
}
.productbox .nav {
  margin-bottom: 25px;
}
.productbox .store-nav-tabs .nav-link {
  color: #82909d !important;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
}
.productbox .store-nav-tabs .nav-link.active {
  font-weight: 700;
  color: #82909d !important;
  border-bottom: 2px solid #82909d;
}
.tab-single .nav-item {
  width: 100%;
  text-align: center;
}
/* .tab-single .nav-link.active {
  display: block !important;
  text-align: center;
} */
.productbox .nav-link:focus,
.productbox .nav-link:hover:focus {
  outline: 0;
}
#sharecamp-popupmob {
  display: none;
}
#logout-ovrly-none {
  background-color: transparent;
  background: none;
}
.custom-tableak .react-pagination-js-bootstrap {
  background: #f9f9f9;
}
.custom-tableak .react-pagination-js-bootstrap ul {
  margin: auto;
  display: table;
  padding: 10px 0;
  box-shadow: none;
}
.custom-tableak .react-pagination-js-bootstrap ul li {
  border: none;
}
.custom-tableak .react-pagination-js-bootstrap .is-active {
  float: left;
  padding: 0px 10px;
  line-height: 25px;
  color: #fff;
  background-color: #2561a8;
  cursor: default;
  border-radius: 5px;
}
.custom-tableak .react-pagination-js-bootstrap .page,
.custom-tableak .react-pagination-js-bootstrap .pageElli {
  line-height: 25px !important;
  color: #333;
}
.border-red {
  border: 3px solid red;
}
/* ------------------Campain Css End ------------------------- */
.broadcastpop label {
  display: block;
}
.channelScript {
  display: block;
}
.broadcastpop .ant-radio-wrapper {
  display: block;
}
.hidedesk {
  display: none;
}
.status-btn-camp {
  display: contents;
}
.midalResponseAction .ant-table-row-collapsed,
.midalResponseAction .ant-table-row-expanded {
  display: none;
}
.custpagination {
  margin-top: 0 !important;
}
.Camp-pagination {
  margin-top: -22px;
}
.Camp-pagination select {
  background: url("./../Images/dropdown3.png") no-repeat right 4px center;
  background-size: 8px;
  padding-right: 17px;
  padding-left: 7px;
}

.campaign-status-drpdwn span {
  color: #4a4a4a;
}
.campaign-status-drpdwn li {
  display: block;
  margin-bottom: 8px;
}
.campaign-status-drpdwn li:last-child {
  margin-bottom: 0;
}
.custom-tableak .ant-table-filter-dropdown {
  border-radius: 10px;
}
.campaign-status-drpdwn {
  padding: 20px 35px;
  /* border-radius: 10px; */
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}
.cust-name-drpdwn {
  padding: 17px 25px 25px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}
.cust-name-drpdwn label {
  margin-bottom: 8px;
}
.cust-name-drpdwn input::placeholder {
  font-weight: 400;
  padding: 0;
}
.campaign-status-drpdwn ul {
  margin: 0;
}
.campaign-status-drpdwn input {
  display: none;
}
.campaign-status-drpdwn label {
  position: relative;
  padding-left: 23px;
}
.campaign-status-drpdwn label::before {
  content: "";
  width: 14px;
  height: 14px;
  border: 1px solid #9a9a9a;
  position: absolute;
  border-radius: 2px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.campaign-status-drpdwn input:checked + label::after {
  content: "";
  width: 4px;
  height: 8px;
  border: solid #9a9a9a;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg) translateY(-50%);
  position: absolute;
  left: 2px;
  top: 42%;
}
.camp-status-header {
  position: relative;
}
.camp-status-header .ant-table-filter-icon {
  position: absolute !important;
  left: 65px;
  top: 50% !important;
  transform: translateY(-50%);
  width: 12px !important;
}
.camp-status-header-cust-name .ant-table-filter-icon {
  left: 122px;
}
.camp-status-header-statusFilter .ant-table-filter-icon {
  left: 65px;
}
.camp-status-header .ant-table-filter-icon::before {
  content: "";
  background: url("./../Images//dropdown3.png") no-repeat center center;
  background-size: 12px;
  display: block;
  height: 6px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
}
.ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-filters.camp-status-header
  .anticon-filter.ant-table-filter-open,
.ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-filters.camp-status-header
  .ant-table-filter-icon.ant-table-filter-open,
.ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-filters.camp-status-header:hover
  .anticon-filter:hover,
.ant-table-thead
  > tr
  > th.ant-table-column-has-actions.ant-table-column-has-filters.camp-status-header:hover
  .ant-table-filter-icon:hover {
  background: transparent;
}

.last-trans-table .ReactTable .rt-thead .rt-th.-cursor-pointer {
  padding: 11px 15px 6px !important;
  font-size: 12px;
  color: #7b7b7b;
}
.last-trans-table .ReactTable .rt-tbody .rt-td {
  padding: 4px 15px !important;
  font-size: 12px;
  color: #4a4a4a;
}
.last-trans-table .ReactTable .rt-tbody .rt-tr-group:hover {
  background-color: transparent;
}
.last-trans-table {
  padding-bottom: 10px;
}
#custMobileFilter {
  margin: 0 auto;
  width: 100%;
  max-width: 260px;
  right: 420px;
  top: 174px;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
}
.ChecknoDataCamp {
  /* text-align: center;
  display: block; */
  font-weight: bold;
  /* margin-top: 103px; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 11px;
}
.keyingsightdrp-invert {
  transform: rotate(180deg);
}
.full-data-insight {
  white-space: initial !important;
}
.storeTickeTtl {
  color: #4934f6;
  font-family: inherit;
  font-size: 19px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  padding-left: 3px;
}
.storelabel-3 {
  margin-top: 0;
  font-size: 16px;
  padding-left: 3px;
  color: #5c5c5c;
}

/* store campaign tab starts */
.store-campaign-inner-table .ant-table-row-expand-icon,
.store-campaign-script-info {
  display: none;
}
.campaign-cust-popup .lifetimevalue {
  height: calc(100% - 18px);
}
/* store campaign tab ends */

/* ------------------Campain Media Queries ------------------------- */

@media (min-width: 768px) and (max-width: 1000px) {
  #customername-popup {
    width: 730px;
  }
  .sharecamp li {
    min-width: 135px;
  }
}
@media (min-width: 320px) and (max-width: 1000px) {
  #customername-popup {
    width: 730px;
  }
  .sharecamp .active {
    background: #fff;
    box-shadow: 0px 0px 5px 0px;
  }
  .shareviabtnmdl {
    width: 100%;
    margin-top: 10px;
    background: #ff8c3f;
    border: none;
    color: #fff;
    outline: none !important;
  }
  .sharecamp ul {
    float: none;
    text-align: center;
  }
  .sharecamp li {
    min-width: 80px;
    margin-right: 10px;
    padding: 15px;
  }
  .sharecamp {
    margin-top: 0;
    /* display: none; */
  }
  .sharecampmob {
    display: none;
  }
  .shareviabtn {
    background-color: #00b894;
    border-radius: 5px;
    padding: 7px;
    color: #ffff;
    margin-top: 15px;
    font-size: 12px;
    display: block;
    text-align: center;
  }
  .shareviabtn .shareviaimg {
    height: 13px;
    margin-right: 5px;
  }
  .headers-menu a {
    font-size: 13px !important;
  }
  .ChecknoDataCamp {
    /* margin: 30px 0; */
    margin: 0;
  }
  .lt-single td {
    display: table-cell;
    padding: 18px 20px;
    text-align: left;
  }
  .productbox {
    margin-top: 0;
  }
  .keyinsights {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 1000px) {
  .cusheade {
    display: none !important;
  }
  .headers-menu .single-menu:first-child {
    margin-left: 10px !important;
  }
  .single-menu {
    /* margin-left: 5px !important; */
    margin-left: 0 !important;
    color: #4a4a4a;
  }
  .single-menu.active {
    color: #2561a8;
    font-weight: 700;
  }
  /* .lowersec .header-icons-cntr img:first-child {
    display: block;
  }
  .lowersec .active .header-icons-cntr img:last-child {
    display: block;
  } */
  .headers-menu {
    display: flex;
    align-items: center;
    max-width: calc(100vw - 235px) !important;
  }
  .chatImg {
    width: 20px !important;
    cursor: pointer;
  }
  .notifi {
    width: 15px !important;
    cursor: pointer;
  }
  .setting {
    width: 18px !important;
    cursor: pointer;
  }
  .header-right-icons a {
    margin-right: 12px !important;
  }
  .custompop {
    right: 0 !important;
    width: 100% !important;
  }
  .antcustom .ant-popover-placement-bottom,
  .antcustom .ant-popover-placement-bottomLeft,
  .antcustom .ant-popover-placement-bottomRight {
    padding: 20px !important;
    left: 0 !important;
  }
  .insertpop1 {
    padding: 0px;
  }
  .antcustom .ant-popover-inner-content {
    padding: 20px;
  }
  .table-coloum-hide {
    display: none !important;
  }

  .particular-hide span {
    visibility: hidden !important;
  }
  .custom-tableak
    .custom-antd-table.antd-table-campaign
    .ant-table-thead
    > tr
    > th {
    background: #e5e9ed !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .custom-tableak
    .custom-antd-table
    .ant-table-thead
    > tr
    > th
    .ant-table-column-title {
    font-size: 11px !important;
  }
  .custom-tableak .custom-antd-table .ant-table-thead > tr > th,
  .custom-react-table .rt-thead .rt-th span {
    padding: 10px 7px 4px !important;
  }
  .custom-tableak
    .custom-antd-table.antd-table-campaign
    .ant-table-tbody
    > tr
    > td {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .custom-tableak .custom-antd-table .ant-table-tbody > tr > td,
  .custom-tableak .custom-react-table .rt-tbody .rt-td {
    padding: 7px !important;
    font-size: 11px;
  }
  .custom-tableak .closebtn {
    width: 60px;
    height: 20px;
    min-width: 42px !important;
  }
  .custom-tableak .hdrcloselabel {
    font-size: 10px;
    vertical-align: super;
  }
  .custom-tableak {
    padding: 5px 0;
  }
  .broadcast-icon img {
    height: 15px;
    cursor: pointer;
  }
  .hidedesk {
    display: inline-block;
  }
  .custom-tableak .cust-name {
    font-size: 12px;
  }
  .custom-tableak .sml-fnt {
    font-size: 10px !important;
  }
  .table-btnlabel {
    font-size: 10px;
    line-height: 20px;
  }
  .notConnectedBtnRed {
    width: 95px;
    height: 22px;
    line-height: 2;
    font-weight: normal;
  }
  .contactBtnGreen {
    width: 95px;
    line-height: 2;
    font-weight: normal;
    height: 22px;
  }
  .followUpBtnYellow {
    width: 95px;
    font-weight: normal;
    line-height: 2;
    height: 22px;
  }
  .followUpBtnBlue {
    width: 95px;
    height: 22px;
    line-height: 2;
    font-weight: 400;
  }
  .custom-tableak .antd-table-campaign .ant-table-row-collapsed::after {
    background-size: 17px 17px;
    width: 17px;
    top: 49%;
    height: 17px;
  }
  .custom-tableak .antd-table-campaign .ant-table-row-expanded::after {
    background-size: 17px 17px;
    width: 17px;
    top: 49%;
    height: 17px;
  }
  .info-icon-cp {
    width: 17px;
    height: 17px;
  }
  .antcustom .ant-popover-placement-bottom,
  .antcustom .ant-popover-placement-bottomLeft,
  .antcustom .ant-popover-placement-bottomRight {
    padding: 20px !important;
    left: 0 !important;
  }
  .custom-tableak
    .custom-antd-table.antd-table-campaign
    .ant-table-thead
    > tr
    > th {
    background: #e5e9ed !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .custom-tableak
    .custom-antd-table
    .ant-table-thead
    > tr
    > th
    .ant-table-column-title {
    font-size: 11px !important;
  }
  .custom-tableak .custom-antd-table .ant-table-thead > tr > th,
  .custom-react-table .rt-thead .rt-th span {
    padding: 10px 7px 4px !important;
  }
  .custom-tableak
    .custom-antd-table.antd-table-campaign
    .ant-table-tbody
    > tr
    > td {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .custom-tableak .custom-antd-table .ant-table-tbody > tr > td,
  .custom-tableak .custom-react-table .rt-tbody .rt-td {
    padding: 7px !important;
    font-size: 11px;
  }
  .cust-icon {
    height: 12px;
  }
  .nr-initials {
    width: 18%;
    font-size: 14px;
  }
  .nr-name {
    float: right;
    padding-left: 5px;
    width: 79%;
  }
  .nr-name h3 {
    font-size: 20px;
    letter-spacing: 0;
  }
  .nr-name span {
    font-size: 14px;
  }
  .nr-name p {
    font-size: 14px;
  }
  .lifetimevalue h4 {
    font-size: 13px;
  }
  .lifetimevalue label {
    font-size: 20px;
  }
  .keyinsights h4 {
    font-size: 13px;
  }
  .keyinsights p {
    font-size: 12px;
    margin-bottom: 5px;
    padding-right: 0;
    max-height: initial;
  }
  .productbox .nav-tabs .nav-link.active {
    font-size: 13px;
  }
  .productbox .nav-tabs .nav-link {
    font-size: 13px;
    display: inline;
  }
  .pro-slidercam h4 {
    font-size: 12px;
    margin-top: 3px;
  }
  /* .sharecamp {
    margin-top: 25px;
  } */
  .cust-name .ico {
    margin-left: 4px;
  }
  .keyingsightdrp {
    display: block;
    height: 7px;
    width: 14px;
    margin: auto;
  }
  .productbox {
    padding: 18px 20px;
    height: auto;
    min-height: 80px;
  }
  .lt-single {
    margin-bottom: 15px;
  }
  .keyinsights {
    padding: 18px 20px;
    height: auto;
  }
  .trasactablist th {
    font-size: 12px;
  }
  .trasactablist td {
    font-size: 12px;
  }
  .transactionbox label {
    font-size: 13px;
  }
  .info-icon-cp {
    bottom: 4px;
  }
  .customernamecam {
    max-width: 85px;
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }
  #customername-popup {
    width: 90%;
    padding: 30px 20px 20px;
  }
  .customername-popupmob .nav-tabs {
    border: none;
    margin: auto;
    display: block;
    text-align: center;
  }
  .customername-popupmob .nav-tabs .nav-link.active {
    color: inherit;
    border: none;
    background: none;
  }
  .customername-popupmob .nav-item:first-child .nav-link {
    color: #ee6261;
    font-weight: 600;
    border-radius: 50px;
    border: 1px solid #ee6261;
    padding: 6px 15px;
    cursor: pointer;
    font-size: 12px;
    min-width: 110px;
    margin-right: 10px;
  }
  .customername-popupmob .nav-item:last-child .nav-link {
    color: #2196f3;
    font-weight: 600;
    border-radius: 50px;
    font-size: 12px;
    cursor: pointer;
    text-align: center;
    min-width: 110px;
    border: 1px solid #2196f3;
    padding: 6px 15px;
  }
  .customername-popupmob .tab-content {
    padding-top: 18px;
    padding-bottom: 0;
  }
  .customername-popupmob .poptitle {
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 15px;
  }
  .channelScript {
    display: block;
    font-size: 11px;
  }
  .camperiod {
    border-top: 1px solid #ddd;
    margin-top: 15px;
    padding-top: 15px;
  }
  .camperiod h4 {
    font-size: 11px;
    font-weight: 600;
    color: #888;
  }
  .camperiod h4 span {
    color: #333;
    margin-left: 15px;
  }
  #sharecamp-popup {
    width: 90%;
    padding: 20px 10px 20px;
    position: relative;
    border-radius: 20px;
  }
  #sharecamp-popup h4 {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  #sharecamp-popup table tbody tr {
    border: none;
  }
  #sharecamp-popup table td {
    width: 50%;
    padding: 0px;
  }
  #sharecamp-popup .chatbox {
    padding: 10px;
    background: #f2f5f8;
    margin: 0 5px 10px 0;
    position: relative;
    border-radius: 10px;
  }
  #sharecamp-popup table td:nth-child(2) .chatbox {
    margin: 0 0 10px 5px;
  }
  #sharecamp-popup table td:nth-child(3) .chatbox {
    margin: 0 5px 0px 0;
  }
  #sharecamp-popup table td:nth-child(4) .chatbox {
    margin: 0 0px 0px 5px;
  }
  #sharecamp-popup .ico {
    display: block;
    height: 30px;
    margin: auto;
    margin-bottom: 6px;
  }
  #sharecamp-popup a {
    text-align: center;
    display: block;
    font-size: 11px;
    font-weight: normal;
    color: #333;
    outline: none;
  }
  #sharecamp-popup .tick {
    height: 13px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ant-prodesc .ant-popover-arrow {
    display: none !important;
  }
  .ant-prodesc {
    left: 15px !important;
  }
  /* .broadcastimg {
  display: none;
} */
  .broadcastpop .broadcasttitle {
    font-size: 15px;
    font-weight: bolder;
    margin-bottom: 10px;
  }
  .broadcastpop .broadcastsubtitle {
    color: #999;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  .broadcastpop .ant-radio-checked .ant-radio-inner {
    border-color: #2561a8;
  }
  .broadcastpop .ant-radio-inner::after {
    background-color: #2561a8;
  }
  .broadcastpop .ant-radio-wrapper {
    display: block;
    margin-bottom: 10px;
  }
  .executebtn {
    background-color: #2561a8;
    border-radius: 4px;
    width: 100%;
    height: 35px;
    min-width: 68px !important;
    cursor: pointer;
    margin-top: 15px;
    color: #fff;
    text-align: center;
    line-height: 0;
  }
  .midalResponseAction .ant-table-row-collapsed,
  .midalResponseAction .ant-table-row-expanded {
    display: block;
  }
  .custom-tableak .txtStore,
  .custom-tableak .txtStore::placeholder {
    font-size: 12px;
  }
  .innertabcollapse .dateTimeStore {
    width: 160px;
    height: 28px;
  }
  .innertabcollapse .responceDrop-down {
    width: 160px;
    height: 28px;
  }
  .responceDrop-down {
    background-size: 10px !important;
    padding-right: 30px !important;
  }
  .custom-tableak .saveLabel {
    text-align: center;
    font-size: 11px;
    width: 60px;
    height: 28px;
  }
  #sharecamp-popupmob {
    display: block;
    border-radius: 20px;
  }
  .productbox .nav-tabs {
    padding: 0;
  }
  .productbox .prodscro {
    padding: 0;
    max-height: 200px;
  }
  #sharesuccesfullpopup .tick {
    height: 40px;
    margin: auto;
    display: block;
    margin-bottom: 15px;
  }
  #sharesuccesfullpopup {
    border-radius: 20px;
    width: 100%;
  }
  #sharesuccesfullpopup h3 {
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    margin-bottom: 10px;
  }
  #sharesuccesfullpopup p {
    font-size: 11px;
    text-align: center;
    margin-bottom: 15px;
  }

  .custom-tableak .react-pagination-js-bootstrap .page,
  .custom-tableak .react-pagination-js-bootstrap .pageElli,
  .custom-tableak .react-pagination-js-bootstrap .is-active {
    padding: 0 7px;
    font-size: 11px;
  }
  .custom-tableak .react-pagination-js-bootstrap ul {
    margin: initial;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .Camp-pagination p {
    display: none;
  }
  .Camp-pagination select {
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .Camp-pagination {
    left: 2px;
  }
  .transactionbox {
    padding: 0;
  }

  .campaign-status-drpdwn,
  .cust-name-drpdwn {
    padding: 15px 20px;
    font-size: 12px;
  }
  .cust-name-drpdwn {
    padding-bottom: 20px;
    max-width: 200px;
  }
  .cust-name-drpdwn label {
    margin-bottom: 5px;
  }
  .cust-name-drpdwn input {
    height: 35px;
  }
  .cust-name-drpdwn input::placeholder {
    font-size: 12px;
  }
  .campaign-status-drpdwn label::before {
    width: 13px;
    height: 13px;
  }
  .camp-status-header .ant-table-filter-icon::before {
    background-size: 10px;
    width: 10px;
    height: 5px;
  }
  .camp-status-header .ant-table-filter-icon {
    left: 45px;
    top: 48% !important;
  }
  .camp-status-header-cust-name .ant-table-filter-icon {
    left: 95px;
  }
  .camp-status-header-statusFilter .ant-table-filter-icon {
    left: 43px;
  }
  .campaign-status-drpdwn input:checked + label::after {
    left: 1.5px;
  }
  .table-drpdwn {
    width: 10px;
  }
  .productbox .store-nav-tabs .nav-link {
    margin-left: 0;
  }
  .productbox .nav {
    margin-bottom: 20px;
  }
  #insight-data {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
/* ------------------Campain Css End ------------------------- */

/* ------------------Appointment Css Start ------------------------- */
.custom-tabcount {
  background: #2561a8;
  display: inline-block;
  width: 100px;
  padding: 4px 8px 4px 12px;
  border-radius: 3px;
  /* margin-left: 25px; */
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tab-title {
  display: inline-block;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
}
.tab-count {
  width: 26px;
  height: 26px;
  line-height: 25px;
  display: inline-block;
  background: white;
  text-align: center;
  border-radius: 2px;
  color: #2561a8;
  font-size: 14px;
  font-weight: 700;
}
.mid-divider {
  width: 0.5px;
  height: 30px;
  background: rgba(255, 255, 255, 0.6);
  display: none;
  margin: 0 10px;
}
.custom-tabs {
  /* margin-bottom: 15px; */
  /* margin-left: -5px; */
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  background: #ecf2f4;
  padding: 12px 20px;
}
.custom-tabcount1 {
  background-color: rgba(37, 97, 168, 0.1);
  display: inline-block;
  width: 100px;
  padding: 4px 8px 4px 12px;
  border-radius: 3px;
  margin-left: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tab-title1 {
  display: inline-block;
  /* width: 20px;
  height: 30px; */
  /* margin-right: 48px; */
  color: #4a4a4a;
  font-size: 15px;
  font-weight: 700;
}
.tab-count1 {
  width: 26px;
  height: 26px;
  line-height: 25px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.5);
  text-align: center;
  border-radius: 2px;
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 700;
}
.custom-tableak .statusBtn {
  background-color: #00b894;
  border: #00b894;
  border-radius: 4px;
  width: 60px;
  height: 30px;
}
.statusLabel {
  color: white;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  text-transform: initial;
}
.appointment-mobile {
  display: none;
}
.appointment-desktop {
  display: table-cell;
  user-select: none;
}
.appt-status .ant-select-focused .ant-select-selection,
.appt-status .ant-select-selection:focus,
.appt-status .ant-select-selection:active {
  box-shadow: none;
  border-color: #e4e4e4;
}
.appt-status .ant-select {
  width: 140px;
}
.appt-status .ant-select-selection {
  border-radius: 3px;
  border-color: #e4e4e4;
}
.appt-status .ant-select-selection:hover {
  border-color: #e4e4e4;
}
.appt-status .ant-select-selection-selected-value {
  font-weight: 400;
  color: #4a4a4a;
}
.appt-status-dropdown {
  border-radius: 10px !important;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1) !important;
}
.appt-status-dropdown ul .ant-select-dropdown-menu-item:last-child {
  background: #fff !important;
}
.appt-status-dropdown
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background: #e6f7ff !important;
}
.appt-status-dropdown .ant-select-dropdown-menu-item {
  color: #7b7b7b;
}
.appt-status .ant-select-selection__placeholder {
  font-weight: 400;
  color: #7b7b7b;
}
.appt-status .ant-select-selection::after {
  content: "";
  position: absolute;
  border: solid #979797;
  border-width: 0 1px 1px 0;
  padding: 3px;
  transform: rotate(45deg);
  right: 12px;
  top: 10px;
}

/* appointment starts chetan */
.appoint-outer-pad {
  padding: 0 15px;
}
.customer-count-block {
  background-color: #ffffff;
  border: 1px solid #e4eff3;
  border-radius: 8px;
  width: 373px;
  padding: 6px 15px;
  margin: 10px auto;
}
.vacancy-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tot-all {
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.tot-cnt {
  color: #2561a8;
  font-size: 12px;
  letter-spacing: 0px;
}
.cust-store {
  font-size: 11px;
  color: #dc6d1f;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.cust-prog-outer {
  width: 100%;
  height: 6px;
  border-radius: 3px;
  background: #e4e4e4;
  position: relative;
  margin: 4px 0;
}
.cust-prog-fill {
  position: absolute;
  top: 0;
  left: 0;
  background: #ff9d58;
  height: 100%;
  border-radius: 3px;
}
.cust-prog-circ {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  border-radius: 50%;
  background: #ff9d58;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
  line-height: 14px;
}
.empty-filled-info {
  display: flex;
}
.empty-filled-info div {
  border-radius: 5px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  margin-left: 10px;
}
.empty-info {
  background: #00b894;
}
.filled-info {
  background: #ff9d58;
  cursor: pointer;
}
.total-info {
  width: 100%;
  margin-right: 5px;
}
.slot-info p {
  font-size: 11px;
  font-weight: 700;
}
.slot-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 9px;
}
.appointment-top-right {
  display: flex;
}
.appointment-top-right .butn {
  padding: 8px 11px;
  color: #2561a8;
  background: #fff;
  border: 1px solid #2561a8;
  border-radius: 3px;
  margin-left: 10px;
  display: inline-block;
}
.appointment-top-right .butn:first-child {
  margin-left: 0;
}
.appoint-search {
  flex: 0 0 16px;
  width: 16px;
  display: inline-block;
  line-height: 1;
}
.appoint-search img {
  width: 100%;
}
.appoint-input {
  border: 0;
  height: 20px;
  width: 0;
  margin-right: 0;
  padding: 0;
}
.appoint-input::placeholder {
  color: #2561a8;
  padding: 0;
  font-weight: 400;
}
.appoint-input-full {
  width: 245px;
  margin-right: 16px;
  margin-left: 6px;
}
.back-butn {
  background-color: #2561a8;
  border-radius: 3px;
  position: relative;
  padding: 7px 15px;
  width: 100px;
  color: #fff;
  text-align: center;
  font-weight: 700;
}
.back-butn:hover {
  color: #fff;
}
.back-butn .arrow-left {
  left: 15px;
}
.appoint-search-res {
  font-weight: 700;
  font-size: 12px;
  display: block;
  margin: 10px 0;
}

#create-appoint-popup {
  max-width: 340px !important;
  border-radius: 20px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
.appnt-top-blue {
  background: #2561a8;
  padding: 15px 20px 20px 20px;
  color: #fff;
}
.appnt-top-blue h5 {
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  text-align: center;
}
.arrow-left {
  border: solid #fff;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg) translateY(50%);
  position: absolute;
  top: 50%;
  left: 5px;
  cursor: pointer;
}
.create-appnt-details h4 {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  margin-top: 25px;
}
.appnt-info-cntr {
  display: flex;
  margin-top: 10px;
}
.appnt-info-cntr p {
  font-size: 12px;
}
.appnt-img-cntr {
  line-height: 1;
  margin-right: 8px;
  flex: 0 0 13px;
  text-align: center;
}
.appnt-bottom-white {
  padding: 20px 25px;
}
.appnt-input-group {
  margin-bottom: 15px;
}
.appnt-input-group label {
  font-size: 12px;
  font-weight: 700;
  color: #000;
  display: block;
}
.appnt-input-group input,
.appnt-input-group select {
  font-size: 12px;
  color: #2561a8;
  border: 0;
  border-bottom: 0.5px solid #000000;
  width: 100%;
  padding: 2px 0;
  margin-top: 3px;
}
.appnt-input-group input::placeholder {
  font-weight: 400;
  padding: 0;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}
.appnt-input-group .react-datepicker-wrapper {
  width: 100%;
}
.appnt-input-group .react-datepicker-wrapper + div button {
  min-width: auto;
}
.appoint-date {
  background: url(/static/media/calendar-black.d0d589e5.svg) no-repeat right 5px
    center;
  background-size: 12px;
  padding-right: 22px !important;
}
.appoint-time {
  color: rgba(0, 0, 0, 0.4) !important;
}
.appnt-input-group select option {
  color: #000;
}
.appnt-input-group select {
  background: url("./../Images/dropDown.png") no-repeat right 5px center;
  background-size: 10px;
  padding-right: 20px;
}
.time-date-sep {
  position: relative;
}
.time-date-sep::before {
  content: "/";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.appnt-input-group input::-webkit-outer-spin-button,
.appnt-input-group input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.appnt-input-group input[type="number"] {
  -moz-appearance: textfield;
}
.appoint-butn {
  background: #07a287;
  color: #fff;
  text-transform: uppercase;
  border: 0;
  padding: 15px 24px;
  margin: 15px auto 0;
  min-width: 185px;
}
.appoint-butn-blue {
  background: #2561a8;
}
.appoint-butn-red {
  background: #e02020;
}
.appoint-butn-orange {
  background: #f37b2b;
}
.appoint-butn-grey {
  background: #aeaeae;
}
.appoint-cancel {
  font-size: 12px;
  color: #000;
  margin-top: 8px;
  display: inline-block;
}
.appoint-cancel:hover {
  color: #000;
}
.ticket-cut {
  position: relative;
}
.ticket-cut::before,
.ticket-cut::after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  left: -10px;
  /* box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.8); */
  background: #909aa9;
}
.ticket-cut::after {
  left: initial;
  right: -10px;
}
.promotional-sms input {
  display: none;
}
.promotional-sms label {
  font-size: 12px;
  color: #2561a8;
  padding: 2px 0;
  margin-top: 3px;
  font-weight: 400;
  position: relative;
  padding-left: 25px;
}
.promotional-sms label::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border: 1px solid #2561a8;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.promotional-sms input:checked + label::after {
  content: "";
  width: 4px;
  height: 8px;
  border: solid #2561a8;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg) translateY(-50%);
  position: absolute;
  left: 3px;
  top: 42%;
}
.verify-img {
  width: 18px;
  line-height: 1;
}
.verify-img img {
  width: 100%;
}
.number-verified {
  display: flex;
  align-items: center;
  margin-left: 2px;
}
.number-verified span {
  color: #9c9c9c;
  font-size: 12px;
  margin-left: 4px;
}

.people-img {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 17px;
}
.people-img img {
  width: 100%;
}
.appoint-update-det .col-md-7 {
  flex: 0 0 54%;
  max-width: 54%;
  padding-right: 5px;
}
.appoint-update-det .col-md-5 {
  flex: 0 0 46%;
  max-width: 46%;
  padding-left: 5px;
}
.appnt-bottom-white-update .appnt-input-group label {
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
}
.people-selection span {
  background-color: #daddec;
  border-radius: 8px;
  width: 26px;
  height: 26px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.people-selection span.active {
  background: #2561a8;
  color: #fff;
}
.people-selection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0 10px;
  z-index: 0;
  margin-top: 15px;
}
.people-selection::before {
  content: "";
  background-color: #daddec;
  border-radius: 2px;
  width: 100%;
  height: 9px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  z-index: -1;
}
.appnt-bottom-white-update .ticket-cut {
  height: 1px;
  margin-top: 30px;
}
.appnt-bottom-white-update .ticket-cut::before {
  left: -35px;
}
.appnt-bottom-white-update .ticket-cut::after {
  right: -35px;
}
.appnt-bottom-white-update .ticket-cut span {
  border: 0.5px dashed #000;
  display: block;
  position: absolute;
  left: -15px;
  width: calc(100% + 30px);
}
.appoint-code {
  background-color: #f4f6f9;
  border-radius: 5px;
  margin: 0 10px;
  padding: 20px;
  text-align: center;
  margin-top: 20px;
}
.appoint-code p {
  font-size: 12px;
  color: #000;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 3px;
}
.appoint-code span {
  font-size: 20px;
  color: #2561a8;
  font-weight: 700;
}
.otp-appoint input {
  font-size: 16px;
}
.otp-appoint-input {
  width: 36px !important;
  margin-right: 10px !important;
}
.resend-otp p {
  color: #9c9c9c;
  font-size: 12px;
}
.resend-otp a {
  color: #2561a8;
  font-size: 12px;
  display: block;
}
.resend-otp a:hover {
  color: #2561a8;
}
.resend-otp {
  margin: 10px 0 15px;
}
.edit-num,
.edit-num:hover {
  color: #2561a8;
}
.otp-appoint-height {
  min-height: 219px;
}
.add-appoint-mob {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 14px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  background: #2561a8;
  border-radius: 15px 15px 0 0;
  padding: 15px;
  display: none;
}
.broadcastpop {
  max-height: 350px;
  overflow: auto;
}
.broadcastpop::-webkit-scrollbar {
  width: 4px;
}
.broadcastpop::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 10px;
}
.broadcastpop::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.broadcastpop::-webkit-scrollbar-thumb:hover {
  background: #555;
}
#promo-sms + label {
  white-space: nowrap;
}
.mobile-appoint-search {
  background: #fff;
  width: 100%;
  border-radius: 5px;
  padding: 20px 15px;
  align-items: center;
  display: none;
}
.mobile-appoint-search .appoint-input {
  width: 100%;
  margin-right: 11px;
}

.custom-tableak .visitedBtn {
  background-color: #ff9d58;
  border: #ff9d58;
}

.custom-tableak .partialBtn {
  background-color: #eb4d4b;
  border: #eb4d4b;
}

.custom-tableak .endVisitBtn {
  background-color: #00b894;
  border: #00b894;
}

.check-svr {
  border: 1px solid #9b9b9b;
  border-radius: 10px;
  width: 35%;
  padding: 25px 0px;
  background: #ffff;
  text-align: center;
  display: inline-block;
  margin-top: 20px;
}
.check-svcBtn {
  width: 180px;
  height: 40px;
  background-color: #2561a8;
  color: #fff;
  margin-top: 25px;
  cursor: pointer;
}
#check-service-tab {
  text-align: center;
}
.main-pincodeDiv {
  text-align: center;
  margin-top: 20px;
}
.mobileblock {
  display: none;
}
@media screen and (max-width: 1000px) {
  .customer-count-block {
    background: #f5f6fc;
    border: 0;
    border-radius: 0;
    width: 100%;
    margin: 0;
    padding: 11px 0 0;
  }
  .total-dis {
    display: none;
  }
  .slot-info {
    background: #f1f1f1;
    margin-top: 11px;
    padding: 2px 15px;
  }
  .vacancy-info {
    padding: 0 15px;
  }
}
/* appointment ends chetan */

@media screen and (max-width: 1000px) {
  .tab-count,
  .tab-count1 {
    width: auto;
    height: auto;
    line-height: initial;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.3);
    background: transparent;
  }
  .tab-count {
    color: #2561a8;
  }
  .mid-divider {
    display: block;
  }
  .tab-title1,
  .tab-title {
    font-size: 11px;
    color: rgba(255, 255, 255, 0.6);
  }
  .tab-title {
    color: #2561a8;
  }
  .custom-tabs {
    padding-right: 15px;
    padding-left: 15px;
    background: #2561a8;
  }
  .appoint-outer-pad {
    padding: 0;
  }
  /* .custom-table-ck .table-cntr {
    padding-top: 11px;
  } */
  .custom-table-ck {
    padding-top: 0;
  }
  .custom-tabcount1,
  .custom-tabcount {
    margin-left: 0;
    margin-right: 20px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.3);
    padding: 15px 12px;
    /* min-width: 100px;
    width: auto; */
    flex-grow: 1;
  }
  .custom-tabcount {
    background: rgba(255, 255, 255, 1);
  }
  .custom-tabcount .mid-divider {
    background: #2561a8;
  }
  .custom-tabs .appnt-date-cntr div:last-child {
    margin-right: 0;
  }
  .appointment-mobile {
    display: table-cell;
  }
  .appointment-desktop {
    display: none;
    user-select: none;
  }
  .appt-cust-name {
    font-size: 12px;
    color: #4a4a4a;
  }
  .appt-cust-mob {
    font-weight: 400;
    color: #7b7b7b;
  }
  .custom-table-ck
    .custom-antd-table.antd-table-campaign
    .ant-table-expanded-row
    .ant-table-tbody
    > tr:first-child
    > td {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .custom-table-ck .statusBtn,
  .custom-table-ck .saveBtn {
    width: 95px;
    height: 22px;
    font-weight: 400;
    margin-right: 0 !important;
  }
  .custom-table-ck .saveBtn {
    line-height: 1.3;
  }
  .custom-table-ck .statusBtn {
    line-height: 1;
  }
  .custom-table-ck .statusLabel {
    font-size: 12px;
  }
  .custom-table-ck .saveLabel {
    font-weight: 400;
  }
  .appt-status .ant-select {
    width: 105px;
  }
  .appt-status .ant-select-selection--single .ant-select-selection__rendered {
    margin-left: 8px;
    margin-right: 18px;
    font-size: 12px;
  }
  .appt-status-dropdown .ant-select-dropdown-menu-item {
    font-size: 12px;
    line-height: 20px;
  }
  .appt-status .ant-select-selection::after {
    right: 7px;
  }
  .appointment-top-right {
    display: none;
  }
  .appnt-date-cntr {
    justify-content: space-between;
    width: 100%;
  }
  .add-appoint-mob {
    display: block;
  }
  .antd-table-appoint {
    padding-bottom: 65px;
  }
  .mobile-appoint-search {
    display: flex;
  }
  .appoint-search-res {
    margin: 0;
    background: #fff;
    font-size: 11px;
    padding: 5px 15px;
  }

  /* ant pagination starts */
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    font-size: 10px !important;
  }
  .ant-pagination-item a {
    font-size: 10px !important;
    padding: 0 4px !important;
  }
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next,
  .ant-pagination-item {
    min-width: 22px !important;
    height: 22px !important;
    line-height: 17px !important;
    margin-right: 5px !important;
  }
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    min-width: 17px !important;
    height: 17px !important;
  }
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis {
    font-size: 10px;
  }
  .ant-pagination-item {
    line-height: 20px !important;
  }
  .ant-select-selection--single {
    font-size: 10px !important;
    height: 22px !important;
  }
  .ant-select-selection__rendered {
    line-height: 20px !important;
    margin-left: 8px !important;
  }
  .ant-pagination-options {
    margin-left: 5px !important;
  }
  .ant-select-dropdown-menu-item {
    padding: 0 7px !important;
    font-size: 10px !important;
  }
  .ant-table-pagination.ant-pagination {
    margin: 10px 0 !important;
  }
  .ant-select-selection--single .ant-select-selection__rendered {
    margin-right: 10px !important;
  }
  /* ant pagination ends */
}

@media screen and (max-width: 1000px) {
  .storemyticketpgn .pagination-bottom {
    display: block !important;
  }
  .storemyticketpgn .ReactTable .-pagination,
  .storemyticketpgn .ReactTable .-pagination .-center {
    flex-wrap: nowrap;
  }
  .storemyticketpgn .ReactTable .-pagination .-btn {
    min-width: auto;
  }
  .storemyticketpgn .ReactTable .-pagination .-pageJump input {
    width: 40px;
  }
  .storemyticketpgn .ReactTable .-pagination .-pageInfo {
    margin-right: 0;
  }
  .ant-pagination-options {
    display: inline-block !important;
  }
  .create-appoint-popup {
    padding: 15px !important;
  }

  /* check service starts */
  .check-svr {
    width: calc(100% - 30px);
    margin-top: 5px;
  }
  /* check service ends */

  /* store campaign tab starts */
  .store-task-tab-cont {
    padding: 0 !important;
  }
  .store-campaign-table .ant-table table {
    border-radius: 0;
  }
  .custom-antd-table.antd-table-campaign.store-campaign-table
    .ant-table-thead
    > tr
    > th {
    padding: 5px 5px !important;
    height: 30px;
  }
  .store-campaign-table.custom-antd-table.antd-table-campaign
    .ant-table-tbody
    > tr
    > td {
    padding: 14px 5px !important;
  }
  .store-campaign-table .ant-table-thead > tr:first-child > th:first-child {
    border-top-left-radius: 0px;
    padding-left: 15px !important;
  }
  .store-campaign-table .ant-table-thead > tr:first-child > th:last-child {
    border-top-right-radius: 0px;
    padding-right: 15px !important;
  }
  .store-campaign-table.custom-antd-table.antd-table-campaign
    .ant-table-tbody
    > tr
    > td:first-child {
    padding-left: 15px !important;
  }
  .store-campaign-table.custom-antd-table.antd-table-campaign
    .ant-table-tbody
    > tr
    > td:last-child {
    padding-right: 15px !important;
    position: relative;
  }
  .store-campaign-table.custom-antd-table.antd-table-campaign
    .ant-table
    .ant-table-row-indent
    + .ant-table-row-expand-icon {
    position: static;
  }
  .store-campaign-table.custom-antd-table.antd-table-campaign
    .ant-table-tbody
    > tr.ant-table-expanded-row
    > td {
    padding: 0 !important;
    /* background: #f0f0f0 !important; */
    background: #dbdbdb !important;
  }
  .store-campaign-inner-table .ant-table-row-expand-icon {
    display: block;
  }
  .store-campaign-inner-table .saveBtn {
    display: none;
  }
  .store-campaign-inner-cntr .saveBtn {
    display: block;
    margin: auto !important;
    margin-top: 20px !important;
    width: 80px;
  }
  .store-campaign-table .cust-name {
    white-space: initial;
  }
  .custom-antd-table.antd-table-campaign.antd-table-campaign-padd.store-campaign-table
    .ant-table-expanded-row
    .ant-table-tbody
    > tr
    > td {
    padding: 12px 5px !important;
  }
  .custom-antd-table.antd-table-campaign.antd-table-campaign-padd.store-campaign-table
    .ant-table-expanded-row
    .ant-table-tbody
    > tr
    > td:first-child {
    padding-left: 15px !important;
  }
  .custom-antd-table.antd-table-campaign.antd-table-campaign-padd.store-campaign-table
    .ant-table-expanded-row
    .ant-table-tbody
    > tr
    > td:last-child {
    padding-right: 15px !important;
  }
  .store-campaign-inner-title {
    color: #4a4a4a;
    margin-bottom: 6px;
  }
  .store-campaign-inner-cntr > div {
    margin-bottom: 15px;
  }
  .store-campaign-inner-cntr > div:last-child {
    margin-bottom: 5px;
  }
  .store-campaign-inner-cntr .table-btnlabel {
    font-size: 12px;
    font-weight: 700;
    line-height: 31px;
    width: 103px;
    height: 31px;
    margin-right: 10px;
  }
  .not-contacted-count {
    right: 12px;
  }
  .store-campaign-inner-cntr .followUpBtnYellow {
    margin-right: 0;
  }
  .store-campaign-script-info {
    display: block;
    padding: 12px 15px;
  }
  .store-campaign-inner-cntr .dateTimeStore {
    width: 190px;
  }
  .mobileblock {
    display: block;
  }
  .campaign-cust-popup .lifetimevalue {
    height: auto;
  }
  .store-task-tabs-mobile {
    display: none;
  }
  /* store campaign tab ends */
}

@media screen and (max-width: 1000px) {
  .custom-tabcount1,
  .custom-tabcount {
    margin-right: 15px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .mid-divider {
    margin: 0 7px;
  }
}
@media screen and (max-width: 1000px) {
  .custom-tabcount1,
  .custom-tabcount {
    margin-right: 12px;
  }
  .tab-count,
  .tab-count1 {
    font-size: 16px;
  }
  .appoint-update-det .col-md-7,
  .appoint-update-det .col-md-5 {
    max-width: 100%;
    flex: 0 0 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .appnt-bottom-white-update .appnt-input-group .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 5px;
  }
  .appnt-bottom-white-update {
    margin-top: -5px;
  }
  .broadcastpop {
    padding: 0;
  }
  #sharecamp-popupmob {
    padding: 20px 14px 5px;
  }
}

/* ------------------Appointment Css End ------------------------- */

/* Datepicker responsive starts */

@media screen and (max-width: 1000px) {
  .date-time-resp .react-datepicker__day-name,
  .date-time-resp .react-datepicker__day,
  .date-time-resp .react-datepicker__time-name {
    width: 1.5rem !important;
    line-height: 1.5rem !important;
  }
  .date-time-resp .react-datepicker__current-month,
  .date-time-resp .react-datepicker-time__header,
  .date-time-resp .react-datepicker-year-header {
    font-size: 14px !important;
  }
  .date-time-resp .react-datepicker-popper {
    margin-left: -119px;
  }
  .date-time-resp .react-datepicker__triangle {
    margin-left: 90px !important;
  }
}

/* Datepicker responsive ends */

@media screen and (max-width: 1000px) {
  .trasactablist {
    margin: 0 -16px;
  }

  /* check service starts */
  .check-svr {
    width: calc(100% - 20px);
  }
  /* check service ends */

  /* store campaign tab starts */
  .store-campaign-table .ant-table-thead > tr:first-child > th:first-child {
    padding-left: 10px !important;
  }
  .store-campaign-table .ant-table-thead > tr:first-child > th:last-child {
    padding-right: 10px !important;
  }
  .store-campaign-table.custom-antd-table.antd-table-campaign
    .ant-table-tbody
    > tr
    > td:first-child {
    padding-left: 10px !important;
  }
  .store-campaign-table.custom-antd-table.antd-table-campaign
    .ant-table-tbody
    > tr
    > td:last-child {
    padding-right: 10px !important;
  }
  .store-campaign-table.custom-antd-table.antd-table-campaign
    .ant-table-tbody
    > tr
    > td {
    font-size: 12px;
  }
  .store-campaign-table .closebtn {
    width: 70px;
  }
  .store-campaign-table.custom-antd-table.antd-table-campaign
    .ant-table-tbody
    > tr.ant-table-expanded-row
    > td {
    padding: 0 !important;
  }
  .store-campaign-table .cust-name {
    font-size: 13px;
  }
  .store-campaign-inner-cntr .responceDrop-down {
    font-size: 12px;
    line-height: 19px;
  }
  .custom-antd-table.antd-table-campaign.antd-table-campaign-padd.store-campaign-table
    .ant-table-expanded-row
    .ant-table-tbody
    > tr
    > td:first-child {
    padding-left: 10px !important;
  }
  .custom-antd-table.antd-table-campaign.antd-table-campaign-padd.store-campaign-table
    .ant-table-expanded-row
    .ant-table-tbody
    > tr
    > td:last-child {
    padding-right: 10px !important;
  }
  .store-campaign-inner-cntr .table-btnlabel {
    width: 93px;
  }
  .store-campaign-inner-cntr .react-datepicker__month {
    margin-right: 0;
    margin-left: 0;
  }
  .store-campaign-inner-cntr .react-datepicker__time-container,
  .store-campaign-inner-cntr
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 75px;
  }
  .store-campaign-script-info {
    padding: 12px 10px;
  }
  /* store campaign tab ends */
}
.camp-response-header .ant-table-filter-icon::before {
  content: "";
  background: url("./../Images//dropdown3.png") no-repeat center center;
  background-size: 12px;
  display: block;
  height: 6px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
}
.camp-callres-header .ant-table-filter-icon::before {
  content: "";
  background: url("./../Images//dropdown3.png") no-repeat center center;
  background-size: 12px;
  display: block;
  height: 6px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
}
.camp-callres-header .ant-table-filter-icon {
  left: 135px !important;
}
.camp-response-header .ant-table-filter-icon {
  left: 80px !important;
}

.camp-goto-div {
  position: absolute;
  left: 85%;
  margin-top: -37px;
}

.camp-goto-div input {
  width: 75px;
  color: #4a4a4a;
  font-size: 12px;
  border: 1px solid #e7eaec;
  border-radius: 4px;
  background-size: 9px;
  padding: 6px 14px 6px 10px;
  margin-right: 10px;
}

.camp-goto-div button {
  padding: 0px 10px;
  line-height: 30px;
  color: #fff;
  background-color: #2561a8;
  cursor: default;
  border-radius: 5px;
  min-width: 60px;
  cursor: pointer;
}

.camp-brod-div {
  position: absolute;
  left: 69%;
  margin-top: -37px;
}
.camp-brod-div button {
  padding: 0px 10px;
  line-height: 30px;
  color: #fff;
  background-color: #2561a8;
  cursor: default;
  border-radius: 5px;
  min-width: 80px;
  cursor: pointer;
  margin-right: 15px;
}

.executeImg {
  width: 20px;
  float: right;
  margin: 3px;
}

.button-green {
  color: #40c351 !important;
  font-weight: 600;
  border-radius: 50px;
  font-size: 12px;
  cursor: pointer;
  border: 1px solid #40c351;
  padding: 6px 20px 9px;
  margin-right: 10px;
}

/* start of css for dropdwon in mobile view in video call appointment */
@media screen and (max-width: 1000px) {
  .ant-select-selection--single {
    height: 37px !important;
    font-size: 15px !important;
    width: 100% !important;
    padding: 7px 0px;
  }

  .ant-select-selection__rendered {
    height: 35px !important;
  }
}
/* end of css for dropdwon in mobile view in video call appointment */
