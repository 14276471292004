.chat-container{
    width: 34px;
    height: 34px;
    position: absolute;
    background: white;
    top: 14%;
    left: 9.5%;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.image-container{
    height: 70%;
    width: 72%;
}

.messenger-logo{
    width: 100%;
    height: 100%;
}
.custom_modal_content .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 350px;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.3rem;
    outline: 0;
}
.custom_modal_content .modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - (0.5rem * 2));
    justify-content: center
}
.custom_modal_content .modal-header .close {
    padding: 5px 10px;
}
.custom_modal_content .modal-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e9ecef !important;
    padding: 10px;
    background: #ff8c3f 50%;
}
.custom_modal_content .modal-footer {
    justify-content: center;
    padding: 10px;
    border-top: 0;
}
.custom_modal_content .modal-footer .btn {
    color: #ffffff;
}
.textarea {
    overflow: auto;
    resize: vertical;
    width: 100%;
    height: 100px;
    border: 1px solid #e9ecef !important;
}
.textarea_container{
    padding: 10px;
}
@media (max-width: 600px) and (min-width: 350px){
    .custom_modal_content .modal-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 350px;
        min-width: 350px;
        pointer-events: auto;
        background-color: #fff;
        background-clip: padding-box;
        border-radius: 0.3rem;
        outline: 0;
    }
}