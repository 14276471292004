.label_checkbox_mobileNo_div{
    display: flex;
    justify-content: space-between;
}
.label_div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.checkbox_label_name{
    margin-right: 10px;
}
.checkbox_div{
    padding:0px 10px;
    width: 35px;
    height: 35px;
    transform: scale(-1, 1);
    background-color: rgb(153, 149, 149);
    border-color: black;
    border:2px solid black;
}
.checkbox_label{
    margin: 0;
    transform: rotate(-45deg);
}
.checkbox_tick{
    color:whitesmoke;
    font-size: 20px;
}
.itemCode_paste{
    position: absolute;
    width: 30px;
    height: 30px;
    line-height: 25px;
    cursor: pointer;
    /* border: 0.5px solid #215faa; */
    border-radius: 50%;
    text-align: center;
    top: 11px;
    right: 33px;
}